.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-formControl.MuiInputLabel-sizeSmall {
  top: 0;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink {
  top: 0;
}
.leaflet-container {
  height: 100%;
}
.MuiAutocomplete-endAdornment {
  top: calc(50% - 18px);
}
.MuiAutocomplete-popper {
  z-index: 2200;
}

.nested-dropdown button {
  width: 100% !important;
  height: 100% !important;
  text-align: right !important;
}

.nested-dropdown button > .MuiButton-endIcon {
  margin-left: auto;
}
